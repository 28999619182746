import React from 'react';

function ThanksForm({ onThanksSubmit }) {
    const handleSubmit = (event) => {
        event.preventDefault();
        // Logique de soumission du formulaire de remerciement

        // Appeler la fonction de soumission du formulaire de remerciement parent
        onThanksSubmit();

    };

    return (
        <div className="loading-container">
            {/* Contenu du formulaire de remerciement */}
            <form onSubmit={handleSubmit}>
                <img src="/checked.png" alt="thansk" className="spinner" />
                <p>!تم الدفع بنجاح</p>
                <p>.سيتم شحن طردك في 7 أيام المقبلة من أوقات العمل</p>
                <button type="submit" className="submit-button" >الصفحة الرئيسية</button>
            </form>
        </div>
    );
}

export default ThanksForm;
