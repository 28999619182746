import React from 'react';

function LoadingSpinner() {
    return (
        <div className="loading-container">
            <img src="/delivery-truck.png" alt="delivery-truck" className="delivery-truck-image" />
            <p>!تأكيد الدفع لتسليم الطلب</p>
            <div className="loader"></div>
            <p>.جاري معالجة طلبك ، المرجوا عدم إغلاق الصفحة</p>
        </div>
    );
}

export default LoadingSpinner;
